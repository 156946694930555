import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import Floor from "../components/floors/floor-3/floor"

const SecondFloorPage = () => (
  <Layout pageTitle={false} sideBar={false}>
    <SEO title="Office Rentals in Bristol - Cotham, Second Floor" />
    <Floor/>
  </Layout>
)

export default SecondFloorPage
 